







import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class FortnoxSetupAfterAuthPage extends Vue {
  private error = ''

  private created() {
    const state = (this.$route.query?.state as string) || ''
    if (!state) {
      this.error = this.$t('c:fortnox-authorize:Missing state parameter')
      return
    }

    const siteId = state.split('-')[2]
    if (!siteId || !/^\d+$/.test(siteId)) {
      this.error = this.$t('c:fortnox-authorize:Invalid state parameter missing site')
      return
    }

    const url =
      '/' +
      siteId +
      '/settings/economy/accounting-integration/setup/overview?install=authorizing&type=fortnox&code=' +
      encodeURIComponent(this.$route.query?.code as string) +
      '&state=' +
      encodeURIComponent(this.$route.query?.state as string)

    this.$router.push(url)
  }
}
